import React from 'react';
import s from './EmptyState.scss';
import {
  Text,
  TextTypography,
  Spinner,
  SpinnerTypes,
} from 'wix-ui-tpa/cssVars';
import { FormAppEmptyState } from '../../../../../images';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { EmptyStateProps } from '.';

export const EmptyState = ({ loading }: EmptyStateProps) => {
  const { t } = useTranslation();
  const { isEditor } = useEnvironment();

  if (!isEditor) {
    return <div data-hook="Form-wrapper" />;
  }

  return (
    <div className={s.container} data-hook="Form-wrapper">
      {loading ? (
        <Spinner type={SpinnerTypes.slim} />
      ) : (
        <>
          <FormAppEmptyState />
          <Text className={s.title} typography={TextTypography.smallTitle}>
            {t('emptyState_title')}
          </Text>
          <Text className={s.description}>{t('emptyState_lineOne')}</Text>
          <Text className={s.description}>{t('emptyState_lineTwo')}</Text>
        </>
      )}
    </div>
  );
};
